/**
 * @ag-grid-community/core - Advanced Data Grid / Data Table supporting Javascript / Typescript / React / Angular / Vue
 * @version v29.3.5
 * @link https://www.ag-grid.com/
 * @license MIT
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Autowired, PostConstruct } from "../context/context";
import { BeanStub } from "../context/beanStub";
import { exists } from "../utils/generic";
import { isIOSUserAgent } from "../utils/browser";
import { capitalise } from "../utils/string";
import { doOnce } from "../utils/function";
var TooltipStates;
(function (TooltipStates) {
    TooltipStates[TooltipStates["NOTHING"] = 0] = "NOTHING";
    TooltipStates[TooltipStates["WAITING_TO_SHOW"] = 1] = "WAITING_TO_SHOW";
    TooltipStates[TooltipStates["SHOWING"] = 2] = "SHOWING";
})(TooltipStates || (TooltipStates = {}));
var CustomTooltipFeature = /** @class */ (function (_super) {
    __extends(CustomTooltipFeature, _super);
    function CustomTooltipFeature(parentComp) {
        var _this = _super.call(this) || this;
        _this.DEFAULT_SHOW_TOOLTIP_DELAY = 2000;
        _this.DEFAULT_HIDE_TOOLTIP_DELAY = 10000;
        _this.SHOW_QUICK_TOOLTIP_DIFF = 1000;
        _this.FADE_OUT_TOOLTIP_TIMEOUT = 1000;
        _this.state = TooltipStates.NOTHING;
        // when showing the tooltip, we need to make sure it's the most recent instance we request, as due to
        // async we could request two tooltips before the first instance returns, in which case we should
        // disregard the second instance.
        _this.tooltipInstanceCount = 0;
        _this.tooltipMouseTrack = false;
        _this.parentComp = parentComp;
        return _this;
    }
    CustomTooltipFeature.prototype.postConstruct = function () {
        this.tooltipShowDelay = this.getTooltipDelay('show') || this.DEFAULT_SHOW_TOOLTIP_DELAY;
        this.tooltipHideDelay = this.getTooltipDelay('hide') || this.DEFAULT_HIDE_TOOLTIP_DELAY;
        this.tooltipMouseTrack = this.gridOptionsService.is('tooltipMouseTrack');
        var el = this.parentComp.getGui();
        this.addManagedListener(el, 'mouseenter', this.onMouseEnter.bind(this));
        this.addManagedListener(el, 'mouseleave', this.onMouseLeave.bind(this));
        this.addManagedListener(el, 'mousemove', this.onMouseMove.bind(this));
        this.addManagedListener(el, 'mousedown', this.onMouseDown.bind(this));
        this.addManagedListener(el, 'keydown', this.onKeyDown.bind(this));
    };
    CustomTooltipFeature.prototype.destroy = function () {
        // if this component gets destroyed while tooltip is showing, need to make sure
        // we don't end with no mouseLeave event resulting in zombie tooltip
        this.setToDoNothing();
        _super.prototype.destroy.call(this);
    };
    CustomTooltipFeature.prototype.onMouseEnter = function (e) {
        if (isIOSUserAgent()) {
            return;
        }
        // every mouseenter should be following by a mouseleave, however for some unkonwn, it's possible for
        // mouseenter to be called twice in a row, which can happen if editing the cell. this was reported
        // in https://ag-grid.atlassian.net/browse/AG-4422. to get around this, we check the state, and if
        // state is !=nothing, then we know mouseenter was already received.
        if (this.state != TooltipStates.NOTHING) {
            return;
        }
        // if another tooltip was hidden very recently, we only wait 200ms to show, not the normal waiting time
        var delay = this.isLastTooltipHiddenRecently() ? 200 : this.tooltipShowDelay;
        this.showTooltipTimeoutId = window.setTimeout(this.showTooltip.bind(this), delay);
        this.lastMouseEvent = e;
        this.state = TooltipStates.WAITING_TO_SHOW;
    };
    CustomTooltipFeature.prototype.onMouseLeave = function () {
        this.setToDoNothing();
    };
    CustomTooltipFeature.prototype.onKeyDown = function () {
        this.setToDoNothing();
    };
    CustomTooltipFeature.prototype.setToDoNothing = function () {
        if (this.state === TooltipStates.SHOWING) {
            this.hideTooltip();
        }
        this.clearTimeouts();
        this.state = TooltipStates.NOTHING;
    };
    CustomTooltipFeature.prototype.onMouseMove = function (e) {
        // there is a delay from the time we mouseOver a component and the time the
        // tooltip is displayed, so we need to track mousemove to be able to correctly
        // position the tooltip when showTooltip is called.
        this.lastMouseEvent = e;
        if (this.tooltipMouseTrack &&
            this.state === TooltipStates.SHOWING &&
            this.tooltipComp) {
            this.positionTooltipUnderLastMouseEvent();
        }
    };
    CustomTooltipFeature.prototype.onMouseDown = function () {
        this.setToDoNothing();
    };
    CustomTooltipFeature.prototype.getTooltipDelay = function (type) {
        var tooltipShowDelay = this.gridOptionsService.getNum('tooltipShowDelay');
        var tooltipHideDelay = this.gridOptionsService.getNum('tooltipHideDelay');
        var delay = type === 'show' ? tooltipShowDelay : tooltipHideDelay;
        var capitalisedType = capitalise(type);
        if (exists(delay)) {
            if (delay < 0) {
                doOnce(function () { return console.warn("AG Grid: tooltip" + capitalisedType + "Delay should not be lower than 0"); }, "tooltip" + capitalisedType + "DelayWarn");
            }
            return Math.max(200, delay);
        }
        return null;
    };
    CustomTooltipFeature.prototype.hideTooltip = function () {
        // check if comp exists - due to async, although we asked for
        // one, the instance may not be back yet
        if (this.tooltipComp) {
            this.destroyTooltipComp();
            CustomTooltipFeature.lastTooltipHideTime = new Date().getTime();
        }
        this.state = TooltipStates.NOTHING;
    };
    CustomTooltipFeature.prototype.destroyTooltipComp = function () {
        var _this = this;
        // add class to fade out the tooltip
        this.tooltipComp.getGui().classList.add('ag-tooltip-hiding');
        // make local copies of these variables, as we use them in the async function below,
        // and we clear then to 'undefined' later, so need to take a copy before they are undefined.
        var tooltipPopupDestroyFunc = this.tooltipPopupDestroyFunc;
        var tooltipComp = this.tooltipComp;
        window.setTimeout(function () {
            tooltipPopupDestroyFunc();
            _this.getContext().destroyBean(tooltipComp);
        }, this.FADE_OUT_TOOLTIP_TIMEOUT);
        this.tooltipPopupDestroyFunc = undefined;
        this.tooltipComp = undefined;
    };
    CustomTooltipFeature.prototype.isLastTooltipHiddenRecently = function () {
        // return true if <1000ms since last time we hid a tooltip
        var now = new Date().getTime();
        var then = CustomTooltipFeature.lastTooltipHideTime;
        return (now - then) < this.SHOW_QUICK_TOOLTIP_DIFF;
    };
    CustomTooltipFeature.prototype.showTooltip = function () {
        var params = __assign({}, this.parentComp.getTooltipParams());
        if (!exists(params.value)) {
            this.setToDoNothing();
            return;
        }
        this.state = TooltipStates.SHOWING;
        this.tooltipInstanceCount++;
        // we pass in tooltipInstanceCount so the callback knows what the count was when
        // we requested the tooltip, so if another tooltip was requested in the mean time
        // we disregard it
        var callback = this.newTooltipComponentCallback.bind(this, this.tooltipInstanceCount);
        var userDetails = this.userComponentFactory.getTooltipCompDetails(params);
        userDetails.newAgStackInstance().then(callback);
    };
    CustomTooltipFeature.prototype.newTooltipComponentCallback = function (tooltipInstanceCopy, tooltipComp) {
        var compNoLongerNeeded = this.state !== TooltipStates.SHOWING || this.tooltipInstanceCount !== tooltipInstanceCopy;
        if (compNoLongerNeeded) {
            this.getContext().destroyBean(tooltipComp);
            return;
        }
        var eGui = tooltipComp.getGui();
        this.tooltipComp = tooltipComp;
        if (!eGui.classList.contains('ag-tooltip')) {
            eGui.classList.add('ag-tooltip-custom');
        }
        var translate = this.localeService.getLocaleTextFunc();
        var addPopupRes = this.popupService.addPopup({
            eChild: eGui,
            ariaLabel: translate('ariaLabelTooltip', 'Tooltip')
        });
        if (addPopupRes) {
            this.tooltipPopupDestroyFunc = addPopupRes.hideFunc;
        }
        // this.tooltipPopupDestroyFunc = this.popupService.addPopup(false, eGui, false);
        this.positionTooltipUnderLastMouseEvent();
        this.hideTooltipTimeoutId = window.setTimeout(this.hideTooltip.bind(this), this.tooltipHideDelay);
    };
    CustomTooltipFeature.prototype.positionTooltipUnderLastMouseEvent = function () {
        this.popupService.positionPopupUnderMouseEvent({
            type: 'tooltip',
            mouseEvent: this.lastMouseEvent,
            ePopup: this.tooltipComp.getGui(),
            nudgeY: 18,
            skipObserver: this.tooltipMouseTrack
        });
    };
    CustomTooltipFeature.prototype.clearTimeouts = function () {
        if (this.showTooltipTimeoutId) {
            window.clearTimeout(this.showTooltipTimeoutId);
            this.showTooltipTimeoutId = undefined;
        }
        if (this.hideTooltipTimeoutId) {
            window.clearTimeout(this.hideTooltipTimeoutId);
            this.hideTooltipTimeoutId = undefined;
        }
    };
    __decorate([
        Autowired('popupService')
    ], CustomTooltipFeature.prototype, "popupService", void 0);
    __decorate([
        Autowired('userComponentFactory')
    ], CustomTooltipFeature.prototype, "userComponentFactory", void 0);
    __decorate([
        PostConstruct
    ], CustomTooltipFeature.prototype, "postConstruct", null);
    return CustomTooltipFeature;
}(BeanStub));
export { CustomTooltipFeature };
